.all-title-box {
  background: url(/src/img/tornos.jpg) no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  min-height: 300px;
  margin-top: initial;
}

.all-title-box h1 {
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
  padding-top: 11%;
  font-size: 48px;
  line-height: 0px;
}

span.m_1 {
  font-weight: 300;
  font-size: 20px;
  line-height: 1.5em;
  display: block;
}

.list-domain {
  margin-top: 30px;
}

.list-domain ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.list-domain ul li {
  display: inline-block;
  padding: 10px 0px;
}

hr.invis {
  border-color: transparent;
}

hr.invis1 {
  margin: 10px 0;
  border-color: transparent;
}

.icon-wrapper {
  position: relative;
  padding: 15px;
  box-shadow: -1px 3px 30px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  display: block;
  z-index: 1;
  min-height: 247px;
}

.icon-wrapper span {
  width: 75px;
  height: 75px;
  text-align: center;
  line-height: 75px;
  font-size: 48px;
  background-color: #3b3838;
  color: #ffffff;
  margin-top: 0;
  position: relative;
  z-index: 1;
}
.icon-wrapper:hover span {
  background-color: #538234;
}
.svg-wrapper {
  width: 75px;
  height: 75px;
  text-align: center;
  line-height: 75px;
  font-size: 48px;
  background-color: #333333;
  color: #ffffff;
  margin-top: 0;
  position: relative;
  z-index: 1;
}

.small-icons.icon-wrapper:hover span,
.small-icons.icon-wrapper:hover span:hover,
.small-icons.icon-wrapper span {
  width: auto !important;
  height: auto !important;
  line-height: 1 !important;
  padding: 0 !important;
  color: #e3e3e3 !important;
  background-color: transparent !important;
  background: none !important;
  margin-right: 10px !important;
  vertical-align: middle;
  font-size: 24px !important;
}

.small-icons.icon-wrapper h3 {
  font-size: 18px;
  padding-bottom: 5px;
}

.small-icons.icon-wrapper p {
  padding: 0;
  margin: 0;
}

.icon-wrapper h3 {
  font-size: 20px;
  font-weight: 500;
  padding: 0 0 15px;
  margin: 0;
}

.icon-wrapper p {
  margin-bottom: 0;
  padding-left: 95px;
}

.icon-wrapper p small {
  display: block;
  color: #999;
  margin-top: 10px;
  text-transform: none;
  font-weight: 600;
  font-size: 16px;
}

.icon-wrapper p small:hover a {
  color: #9dc15b;
}

.icon-wrapper p small:after {
  content: "\f105";
  font-family: FontAwesome;
  margin-left: 5px;
  font-size: 11px;
}

.table-responsive-sm .table td,
.table-responsive-sm .table th {
  vertical-align: middle;
  white-space: nowrap;
}

.alignleft {
  float: left;
  margin: 10px 20px 20px 0;
}

hr.hr3 {
  position: relative;
  margin: 25px 0 30px 0;
  border: 0px dashed #f2f3f5;
}
hr:not([size]) {
  height: 0px;
}

@media (max-width: 767px) {
  .icon-wrapper h3 {
    font-size: 14px;
  }
  .icon-wrapper p {
    font-size: 13px;
  }
  .icon-wrapper {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .icon-wrapper h3 {
    clear: both;
  }
  .icon-wrapper p {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 280px) and (max-width: 599px) {
  .icon-wrapper h3 {
    font-size: 14px;
  }
  .icon-wrapper p {
    font-size: 15px;
  }
  .icon-wrapper {
    margin-bottom: 30px;
  }
}

#about {
  padding: 100px 0;
  font-family: "Quicksand", "sans-serif";
}
#about h3 {
  font-size: 22px;
  margin: 0 0 20px 0;
}
#about h2 {
  font-size: 42px;
  font-weight: 500;
  line-height: 62px;
  margin: 0 0 25px;
  padding: 0;
  text-transform: none;
}

#about .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
  font-size: 20px;
}
#about .about-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #538234;
  font-size: 15px;
  font-weight: 300;
  padding-right: 8px;
}
#about img {
  width: 100%;
  max-width: 554px;
  margin-top: 10px;
  background: #fff;
  border-right: 0;
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.33);
}
#about p {
  line-height: 24px;
  margin: 30px 0;
}

@media (max-width: 768px) {
  #about img {
    margin: 50px 0;
  }
}

@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#menu {
  padding: 15px;
  transition: all 0.8s;
}
#menu.custom-navbar {
  /* background-color: #fff; */
  border-color: rgba(231, 231, 231, 0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
#menu a.navbar-brand {
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #3b3838;
  text-transform: uppercase;
}
#menu.custom-navbar .navbar-nav > li > a {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: #3b3838;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 9px 20px 0 20px;
}
.lineAnimation {
  display: block;
  left: 0;
  margin-top: 4px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #538234 0%, #82b260 100%);
  transition: width 0.2s;
}
#menu.custom-navbar .navbar-nav > li > a:hover .lineAnimation {
  width: 100%;
}
.activeLink {
  width: 100%;
}

.logo {
  width: 59px;
  margin-left: 32px;
}

@media only screen and (min-width: 280px) and (max-width: 599px) {
  .logo {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  .logo {
    margin-left: 0px;
  }
}

.cac {
  background-color: #232323;
  transition: all 0.3s ease-in-out;
}

.cac:hover a h3 {
  color: #fff !important;
}

.cac a h3 {
  color: #999;
}

.cac h3 {
  padding: 60px 0;
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  text-transform: capitalize;
  line-height: 1 !important;
}

.footer {
  padding: 60px 0 50px !important;
  color: #999;
  background: #3b3838;
}

.footer .widget-title {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.footer .widget-title small {
  color: #999;
  display: block;
  padding: 0 58px;
  text-transform: uppercase;
}

.footer .widget-title h3 {
  color: #fff;
  font-weight: 700;
  font-size: 22px;
  padding: 0;
  margin: 0;
  line-height: 1 !important;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links a {
  color: #999;
}

.footer-links a:hover,
.footer a:hover {
  color: #f4f4f4 !important;
}

.footer-links li {
  margin-bottom: 10px;
  display: block;
  width: 100%;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
  padding-bottom: 10px;
}

.twitter-widget li {
  margin-bottom: 0;
  border: 0 !important;
}

.twitter-widget li i {
  border-right: 0 !important;
  margin-right: 0;
}

.footer-links li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: 0;
}

.footer-links i {
  display: inline-block;
  width: 25px;
  margin-right: 10px;
  border-right: 1px dashed rgba(255, 255, 255, 0.1);
}

.copyrights {
  border-top: 1px dashed rgba(255, 255, 255, 0.1);
  background-color: #1f1f1f;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  padding: 30px 0px;
  overflow: hidden;
}

/* Footer left */

.footer-distributed .footer-left {
  float: left;
}

.footer-distributed .footer-links {
  margin: 0 0 10px;
  text-transform: uppercase;
  padding: 0;
}

.footer-distributed .footer-links a {
  display: inline-block;
  line-height: 1.8;
  margin: 0 10px 0 10px;
  text-decoration: none;
}

.footer-distributed .footer-company-name {
  font-weight: 300;
  margin: 0 10px;
  color: #666;
  padding: 5px 0px;
}

/* Footer right */

.footer-distributed .footer-right {
  float: right;
}

/* The search form */

.footer-distributed form {
  position: relative;
}

.footer-distributed form input {
  display: block;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: #181818;
  border: none;
  font: inherit;
  font-size: 15px;
  font-weight: normal;
  color: #999;
  width: 400px;
  padding: 18px 50px 18px 18px;
}

.footer-distributed form input:focus {
  outline: none;
}

/* Changing the placeholder color */

.footer-distributed form input::-webkit-input-placeholder {
  color: #999;
}

.footer-distributed form input::-moz-placeholder {
  opacity: 1;
  color: #999;
}

.footer-distributed form input:-ms-input-placeholder {
  color: #999;
}

/* The magnify glass icon */

.footer-distributed form i {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 16px;
  right: 18px;
  color: #999;
  font-size: 18px;
  margin-top: 6px;
}

.footer-distributed .footer-company-name span {
  color: #ffffff;
  cursor: pointer;
}

.footer-distributed .footer-company-name a:hover {
  color: #9dc15b;
}
.footer-links-soi {
  margin: 0px;
  padding: 0px;
}
.footer-links-soi li {
  display: inline-block;
  margin-right: 5px;
}
.footer-links-soi li a {
  background: #ffffff;
  color: #333333;
  width: 32px;
  height: 32px;
  display: block;
  font-size: 22px;
  text-align: center;
  line-height: 32px;
}
.footer-links-soi li a:hover {
  background: linear-gradient(0deg, #538234 30%, #82b260 67%);
}
.footer-links-soi li a:hover svg {
  color: #ffffff;
  -webkit-transform: rotateY(360deg);
  transform: rotateY(360deg);
}

.footer-links-soi li a i {
  transition: all 0.6s ease;
}

@media (max-width: 767px) {
  .copyrights {
    padding: 20px 0px;
    text-align: center;
  }
  .footer .widget {
    margin-bottom: 30px;
  }
  .footer-distributed .footer-right {
    float: none;
  }
  .footer-left {
    float: none !important;
  }
}
@media only screen and (min-width: 280px) and (max-width: 599px) {
  .copyrights {
    padding: 20px 0px;
    text-align: center;
  }
  .footer-distributed .footer-right {
    float: none;
  }
  .footer .widget {
    margin-bottom: 30px;
  }
  .footer-left {
    float: none !important;
  }
}

.intro {
  display: table;
  width: 100%;
  padding: 0;
  background: url(/static/media/intro-bg.3b91ccd8.jpg) center center no-repeat;
  background-color: #e5e5e5;
  background-size: cover;
  -o-background-size: cover;
}
.intro .overlay {
  background: rgba(0, 0, 0, 0.2);
}
.intro h1 {
  font-family: "Raleway", sans-serif;
  color: #fff;
  font-size: 82px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
}
.intro h1 span {
  font-weight: 800;
  color: #5ca9fb;
}
.intro p {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 60px;
}
header .intro-text {
  padding-top: 350px;
  padding-bottom: 200px;
  text-align: center;
}

/* .carousel .carousel-item {
  height: 850px;
}
.carousel-item img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  min-height: 850px;
} */

#header {
  margin-top: calc(100% - (100% - 93px));
}

.section {
  font-family: "Quicksand", sans-serif;
  color: #333333;
  font-size: 15px;
  line-height: 1.80857;
  display: block;
  position: relative;
  overflow: hidden;
  padding: 70px 0;
}
h2,
h3,
h4 {
  font-family: "Quicksand", sans-serif;
}
a {
  text-decoration: none;
}
.section.lb {
  background-color: #f2f3f5;
}

.section-title {
  display: block;
  position: relative;
  margin-bottom: 60px;
}

.section-title p {
  color: #656262;
  font-weight: 400;
  font-size: 20px;
  line-height: 33px;
  margin: 0;
}

.section-title h3 {
  font-size: 42px;
  font-weight: 500;
  line-height: 62px;
  margin: 0 0 25px;
  padding: 0;
  text-transform: none;
}

.post-media {
  position: relative;
}

.post-media img {
  width: 80%;
}
.message-box {
  padding: 20px 0px;
}

.message-box a {
  position: relative;
  padding: 13px 40px;
  font-size: 18px;
  display: inline-block;
}
.message-box a,
.message-box a.hover-btn-new::after {
  transition: all 0.3s;
}

.message-box a.hover-btn-new::before,
.message-box a.hover-btn-new::after {
  background: #538234;
  background: radial-gradient(circle, #82b260 32%, #538234 100%);
  content: "";
  position: absolute;
  z-index: 1;
}

.message-box a.hover-btn-new:hover span {
  color: #ffffff;
}
.message-box a.hover-btn-new::before {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}
.message-box a.hover-btn-new::after {
  background: #3b3838 !important;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}
.message-box a.hover-btn-new span {
  position: relative;
  z-index: 2;
  color: #fff;
}
.message-box a.hover-btn-new:hover:after {
  height: 0;
  left: 50%;
  top: 50%;
  width: 0;
}
.message-box h4 {
  text-transform: uppercase;
  padding: 0;
  margin: 0 0 5px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 15px;
  color: #999;
}

.message-box h2 {
  font-size: 38px;
  font-weight: 400;
  padding: 0 0 10px;
  margin: 0;
  line-height: 62px;
  margin-top: 0;
  text-transform: none;
}

.message-box p {
  margin-bottom: 20px;
}

.message-box .lead {
  padding-top: 10px;
  font-size: 19px;
  font-style: italic;
  color: #999;
  padding-bottom: 0;
}
.align-items-center {
  margin-bottom: 30px;
}

/* @media (min-width: 1400px) {
  .mw-1140 {
    max-width: 1140px;
  }
} */

.dbcolor {
  background: linear-gradient(to right, #538234 0%, #82b260 100%);
}

.parallax {
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  width: 100%;
}

.parallax.parallax-off {
  background-attachment: fixed !important;
  display: block;
  overflow: hidden;
  position: relative;
  background-position: center center;
  vertical-align: sub;
  width: 100%;
  z-index: 2;
}

.logos img {
  margin: auto;
  display: block;
  text-align: center;
  width: 100%;
  opacity: 0.3;
}

.logos img:hover {
  opacity: 1;
}

.all-title-box {
  background: url(/static/media/tornos.5024ddec.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 300px;
  margin-top: 0;
  margin-top: initial;
}

.all-title-box h1 {
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
  padding-top: 11%;
  font-size: 48px;
  line-height: 0px;
}

span.m_1 {
  font-weight: 300;
  font-size: 20px;
  line-height: 1.5em;
  display: block;
}

.list-domain {
  margin-top: 30px;
}

.list-domain ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.list-domain ul li {
  display: inline-block;
  padding: 10px 0px;
}

hr.invis {
  border-color: transparent;
}

hr.invis1 {
  margin: 10px 0;
  border-color: transparent;
}

.icon-wrapper {
  position: relative;
  padding: 15px;
  box-shadow: -1px 3px 30px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  display: block;
  z-index: 1;
  min-height: 247px;
}

.icon-wrapper span {
  width: 75px;
  height: 75px;
  text-align: center;
  line-height: 75px;
  font-size: 48px;
  background-color: #3b3838;
  color: #ffffff;
  margin-top: 0;
  position: relative;
  z-index: 1;
}
.icon-wrapper:hover span {
  background-color: #538234;
}
.svg-wrapper {
  width: 75px;
  height: 75px;
  text-align: center;
  line-height: 75px;
  font-size: 48px;
  background-color: #333333;
  color: #ffffff;
  margin-top: 0;
  position: relative;
  z-index: 1;
}

.small-icons.icon-wrapper:hover span,
.small-icons.icon-wrapper:hover span:hover,
.small-icons.icon-wrapper span {
  width: auto !important;
  height: auto !important;
  line-height: 1 !important;
  padding: 0 !important;
  color: #e3e3e3 !important;
  background-color: transparent !important;
  background: none !important;
  margin-right: 10px !important;
  vertical-align: middle;
  font-size: 24px !important;
}

.small-icons.icon-wrapper h3 {
  font-size: 18px;
  padding-bottom: 5px;
}

.small-icons.icon-wrapper p {
  padding: 0;
  margin: 0;
}

.icon-wrapper h3 {
  font-size: 20px;
  font-weight: 500;
  padding: 0 0 15px;
  margin: 0;
}

.icon-wrapper p {
  margin-bottom: 0;
  padding-left: 95px;
}

.icon-wrapper p small {
  display: block;
  color: #999;
  margin-top: 10px;
  text-transform: none;
  font-weight: 600;
  font-size: 16px;
}

.icon-wrapper p small:hover a {
  color: #9dc15b;
}

.icon-wrapper p small:after {
  content: "\f105";
  font-family: FontAwesome;
  margin-left: 5px;
  font-size: 11px;
}

.table-responsive-sm .table td,
.table-responsive-sm .table th {
  vertical-align: middle;
  white-space: nowrap;
}

.alignleft {
  float: left;
  margin: 10px 20px 20px 0;
}

hr.hr3 {
  position: relative;
  margin: 25px 0 30px 0;
  border: 0px dashed #f2f3f5;
}
hr:not([size]) {
  height: 0px;
}

@media (max-width: 767px) {
  .icon-wrapper h3 {
    font-size: 14px;
  }
  .icon-wrapper p {
    font-size: 13px;
  }
  .icon-wrapper {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .icon-wrapper h3 {
    clear: both;
  }
  .icon-wrapper p {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 280px) and (max-width: 599px) {
  .icon-wrapper h3 {
    font-size: 14px;
  }
  .icon-wrapper p {
    font-size: 15px;
  }
  .icon-wrapper {
    margin-bottom: 30px;
  }
}

#about {
  padding: 100px 0;
  font-family: "Quicksand", "sans-serif";
}
#about h3 {
  font-size: 22px;
  margin: 0 0 20px 0;
}
#about h2 {
  font-size: 42px;
  font-weight: 500;
  line-height: 62px;
  margin: 0 0 25px;
  padding: 0;
  text-transform: none;
}

#about .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
  font-size: 20px;
}
#about .about-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #538234;
  font-size: 15px;
  font-weight: 300;
  padding-right: 8px;
}
#about img {
  width: 100%;
  max-width: 554px;
  margin-top: 10px;
  background: #fff;
  border-right: 0;
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.33);
}
#about p {
  line-height: 24px;
  margin: 30px 0;
}

@media (max-width: 768px) {
  #about img {
    margin: 50px 0;
  }
}

#portfolio {
  padding: 70px 0;
  text-align: center;
}
#portfolio h2 {
  font-family: "Quicksand", "sans-serif";
  font-size: 42px;
  font-weight: 500;
  line-height: 62px;
  margin: 0 0 25px;
  padding: 0;
  text-transform: none;
}
.portfolio-item {
  margin: 1px -9px 0 -14px;
  padding: 0;
}
.portfolio-item .hover-bg {
  overflow: hidden;
  position: relative;
  margin: 0;
}
.hover-bg .hover-text {
  position: absolute;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  background: linear-gradient(
    to right,
    rgba(99, 114, 255, 0.8) 0%,
    rgba(92, 169, 251, 0.8) 100%
  );
  padding: 30% 0 0 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
}
.hover-bg .hover-text > h4 {
  opacity: 0;
  color: #fff;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: all 0.3s;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
}
.hover-bg:hover .hover-text > h4 {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.hover-bg:hover .hover-text {
  opacity: 1;
}

.all-title-box-gallery {
  background: url(/static/media/desenfocadogallery.d2273b87.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 300px;
  margin-top: 0;
  margin-top: initial;
}

.row img {
  cursor: pointer;
}

.all-title-box-contact {
  background: url(/static/media/desenfocadocontact.12667128.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 300px;
  margin-top: 0;
  margin-top: initial;
}

.bootstrap-select {
  width: 100% \0;
  /*IE9 and below*/
}

.bootstrap-select > .dropdown-toggle {
  width: 100%;
  padding-right: 25px;
}

.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle {
  border-color: #b94a48;
}

.bootstrap-select.fit-width {
  width: auto !important;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.bootstrap-select .dropdown-toggle:focus {
  outline: thin dotted #333333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px;
}

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none;
}

.bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%;
}

.bootstrap-select.form-control.input-group-btn {
  z-index: auto;
}

.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0;
}

.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*="col-"].dropdown-menu-right,
.row .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
  float: right;
}

.form-inline .bootstrap-select.btn-group,
.form-horizontal .bootstrap-select.btn-group,
.form-group .bootstrap-select.btn-group {
  margin-bottom: 0;
}

.form-group-lg .bootstrap-select.btn-group.form-control,
.form-group-sm .bootstrap-select.btn-group.form-control {
  padding: 0;
}

.form-inline .bootstrap-select.btn-group .form-control {
  width: 100%;
}

.bootstrap-select.btn-group.disabled,
.bootstrap-select.btn-group > .disabled {
  cursor: not-allowed;
}

.bootstrap-select.btn-group.disabled:focus,
.bootstrap-select.btn-group > .disabled:focus {
  outline: none !important;
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left;
}

.bootstrap-select.btn-group .dropdown-toggle .fa-angle-down {
  position: absolute;
  top: 30% !important;
  right: -5px;
  vertical-align: middle;
}

.bootstrap-select.btn-group[class*="col-"] .dropdown-toggle {
  width: 100%;
}

.bootstrap-select.btn-group .dropdown-menu {
  border: 1px solid #ededed;
  box-shadow: none;
  box-sizing: border-box;
  min-width: 100%;
  padding: 20px 10px;
  z-index: 1035;
}

.dropdown-menu > li > a {
  background-color: transparent !important;
  color: #bcbcbc !important;
  font-size: 15px;
  padding: 10px 20px;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
}

.bootstrap-select.btn-group .dropdown-menu li {
  position: relative;
}

.bootstrap-select.btn-group .dropdown-menu li.active small {
  color: #fff;
}

.bootstrap-select.btn-group .dropdown-menu li.disabled a {
  cursor: not-allowed;
}

.bootstrap-select.btn-group .dropdown-menu li a {
  cursor: pointer;
}

.bootstrap-select.btn-group .dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em;
}

.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
  display: none;
}

.bootstrap-select.btn-group .dropdown-menu li a span.text {
  display: inline-block;
}

.bootstrap-select.btn-group .dropdown-menu li small {
  padding-left: 0.5em;
}

.bootstrap-select.btn-group .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: 0.9;
  box-sizing: border-box;
}

.bootstrap-select.btn-group .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap;
}

.bootstrap-select.btn-group.fit-width .dropdown-toggle .filter-option {
  position: static;
}

.bootstrap-select.btn-group.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: 4px;
}

.bootstrap-select.btn-group.show-tick
  .dropdown-menu
  li.selected
  a
  span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  margin-top: 5px;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
  margin-right: 34px;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle {
  z-index: 1036;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
  content: "";
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
  bottom: auto;
  top: -3px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
  bottom: auto;
  top: -3px;
  border-top: 6px solid white;
  border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
  right: 12px;
  left: auto;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
  right: 13px;
  left: auto;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
  display: block;
}

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px;
}

.bs-actionsbox {
  float: left;
  width: 100%;
  box-sizing: border-box;
}

.bs-actionsbox .btn-group button {
  width: 50%;
}

.bs-donebutton {
  float: left;
  width: 100%;
  box-sizing: border-box;
}

.bs-donebutton .btn-group button {
  width: 100%;
}

.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px;
}

.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
}

select.bs-select-hidden,
select.selectpicker {
  display: none !important;
}

select.mobile-device {
  position: absolute !important;
  top: 0;
  left: 0;
  display: block !important;
  width: 100%;
  height: 100% !important;
  opacity: 0;
}

/*# sourceMappingURL=bootstrap-select.css.map */

.bootstrap-select > .btn {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  font-size: 15px;
  height: 33px;
  box-shadow: none !important;
  border: 0 !important;
  padding: 0;
  width: 100%;
  color: #bcbcbc !important;
}

.contact_form {
  border: none;
  box-shadow: 0 5px 14px rgba(0, 0, 0, 0.1);
  background-color: #3b3838;
  padding: 40px 30px;
  margin-right: -15px;
}

.contact_form .form-control {
  background-color: #fff;
  margin-bottom: 30px;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  color: #bcbcbc;
  font-size: 16px;
  outline: 0 none;
  padding: 10px 25px;
  height: 55px;
  resize: none;
  box-shadow: none !important;
  width: 100%;
}

.contact_form textarea {
  color: #bcbcbc;
  padding: 20px 25px !important;
  height: 160px !important;
}

.contact_form .form-control::-webkit-input-placeholder {
  color: #bcbcbc;
}

.contact_form .form-control::-moz-placeholder {
  opacity: 1;
  color: #bcbcbc;
}

.contact_form .form-control::-ms-input-placeholder {
  color: #bcbcbc;
}

/* #contact {
  background: url(images/bg.png) no-repeat center center #fff;
} */

.map-box {
  margin-left: -15px;
  height: 100%;
}

.grd1 {
  color: #ffffff !important;
  background: #3b3838;
}
.grd1:hover,
.grd1:focus {
  background-position: 100px;
  color: #ffffff !important;
  background: #538234;
}

.pd {
  padding: 0px 15px;
}

.btn-light {
  padding: 13px 40px;
  font-size: 18px;
  border: 2px solid #ffffff !important;
  color: #ffffff;
  background-color: transparent;
}

.btn-light:hover,
.btn-light:focus {
  border-color: rgba(255, 255, 255, 0.6);
  color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 1200px) {
  .map-box {
    height: 500px;
    padding-top: 20px;
  }
}

body,
html {
  font-family: "Open Sans", sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #777;
  font-weight: 400;
  width: 100% !important;
  height: 100% !important;
}

h2,
h3,
h4 {
  font-family: "Raleway", sans-serif;
}
h2 {
  text-transform: uppercase;
  margin: 0 0 20px 0;
  font-weight: 800;
  font-size: 36px;
  color: #3b3838;
}
h3 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}
h4 {
  font-size: 18px;
  color: #333;
  font-weight: 600;
}
h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px;
}
p {
  font-size: 17px;
}
p.intro {
  margin: 12px 0 0;
  line-height: 24px;
}
a {
  color: #608dfd;
  font-weight: 400;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #608dfd;
}
ul,
ol {
  list-style: none;
}
ul,
ol {
  padding: 0;
  webkit-padding: 0;
  moz-padding: 0;
}
hr {
  height: 2px;
  width: 70px;
  text-align: center;
  position: relative;
  background: #1e7a46;
  margin-bottom: 20px;
  border: 0;
}

.all-title-box-aboutus {
  background: url(/static/media/desenfoqueaboutus.1da368a7.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 300px;
  margin-top: 0;
  margin-top: initial;
}
.shadow-img {
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.33);
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

#notfound {
  position: relative;
  height: 100vh;
  background: #f6f6f6;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  padding: 110px 40px;
  text-align: center;
  background: #fff;
  box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
}

.notfound .notfound-404 {
  position: relative;
  height: 180px;
}

.notfound .notfound-404 h1 {
  font-family: "Roboto", sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 165px;
  font-weight: 700;
  margin: 0px;
  color: #262626;
  text-transform: uppercase;
}

.notfound .notfound-404 h1 > span {
  color: #538234;
}

.notfound h2 {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  color: #151515;
  margin-top: 0px;
  margin-bottom: 25px;
}

.notfound .notfound-search {
  position: relative;
  max-width: 320px;
  width: 100%;
  margin: auto;
}

.notfound .notfound-search > input {
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 50px;
  padding: 3px 65px 3px 30px;
  color: #151515;
  font-size: 16px;
  background: transparent;
  border: 2px solid #c5c5c5;
  border-radius: 40px;
  transition: 0.2s all;
}

.notfound .notfound-search > input:focus {
  border-color: #00b7ff;
}

.notfound .notfound-search > button {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 40px;
  height: 40px;
  text-align: center;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.notfound .notfound-search > button > span {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
  margin-left: -3px;
}

.notfound .notfound-search > button > span:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  border: 4px solid #c5c5c5;
  transition: 0.2s all;
}

.notfound-search > button > span:before {
  position: absolute;
  content: "";
  width: 4px;
  height: 10px;
  left: 7px;
  top: 17px;
  border-radius: 2px;
  background: #c5c5c5;
  transition: 0.2s all;
}

.notfound .notfound-search > button:hover > span:after {
  border-color: #00b7ff;
}

.notfound .notfound-search > button:hover > span:before {
  background-color: #00b7ff;
}

@media only screen and (max-width: 767px) {
  .notfound h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 141px;
  }
}


#portfolio {
  padding: 70px 0;
  text-align: center;
}
#portfolio h2 {
  font-family: "Quicksand", "sans-serif";
  font-size: 42px;
  font-weight: 500;
  line-height: 62px;
  margin: 0 0 25px;
  padding: 0;
  text-transform: none;
}
.portfolio-item {
  margin: 1px -9px 0 -14px;
  padding: 0;
}
.portfolio-item .hover-bg {
  overflow: hidden;
  position: relative;
  margin: 0;
}
.hover-bg .hover-text {
  position: absolute;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  background: linear-gradient(
    to right,
    rgba(99, 114, 255, 0.8) 0%,
    rgba(92, 169, 251, 0.8) 100%
  );
  padding: 30% 0 0 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
}
.hover-bg .hover-text > h4 {
  opacity: 0;
  color: #fff;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: all 0.3s;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
}
.hover-bg:hover .hover-text > h4 {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.hover-bg:hover .hover-text {
  opacity: 1;
}

.all-title-box-gallery {
  background: url(/src/img/desenfocadogallery.jpg) no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  min-height: 300px;
  margin-top: initial;
}

.row img {
  cursor: pointer;
}

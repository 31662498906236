.cac {
  background-color: #232323;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cac:hover a h3 {
  color: #fff !important;
}

.cac a h3 {
  color: #999;
}

.cac h3 {
  padding: 60px 0;
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  text-transform: capitalize;
  line-height: 1 !important;
}

.footer {
  padding: 60px 0 50px !important;
  color: #999;
  background: #3b3838;
}

.footer .widget-title {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.footer .widget-title small {
  color: #999;
  display: block;
  padding: 0 58px;
  text-transform: uppercase;
}

.footer .widget-title h3 {
  color: #fff;
  font-weight: 700;
  font-size: 22px;
  padding: 0;
  margin: 0;
  line-height: 1 !important;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links a {
  color: #999;
}

.footer-links a:hover,
.footer a:hover {
  color: #f4f4f4 !important;
}

.footer-links li {
  margin-bottom: 10px;
  display: block;
  width: 100%;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
  padding-bottom: 10px;
}

.twitter-widget li {
  margin-bottom: 0;
  border: 0 !important;
}

.twitter-widget li i {
  border-right: 0 !important;
  margin-right: 0;
}

.footer-links li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: 0;
}

.footer-links i {
  display: inline-block;
  width: 25px;
  margin-right: 10px;
  border-right: 1px dashed rgba(255, 255, 255, 0.1);
}

.copyrights {
  border-top: 1px dashed rgba(255, 255, 255, 0.1);
  background-color: #1f1f1f;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  padding: 30px 0px;
  overflow: hidden;
}

/* Footer left */

.footer-distributed .footer-left {
  float: left;
}

.footer-distributed .footer-links {
  margin: 0 0 10px;
  text-transform: uppercase;
  padding: 0;
}

.footer-distributed .footer-links a {
  display: inline-block;
  line-height: 1.8;
  margin: 0 10px 0 10px;
  text-decoration: none;
}

.footer-distributed .footer-company-name {
  font-weight: 300;
  margin: 0 10px;
  color: #666;
  padding: 5px 0px;
}

/* Footer right */

.footer-distributed .footer-right {
  float: right;
}

/* The search form */

.footer-distributed form {
  position: relative;
}

.footer-distributed form input {
  display: block;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: #181818;
  border: none;
  font: inherit;
  font-size: 15px;
  font-weight: normal;
  color: #999;
  width: 400px;
  padding: 18px 50px 18px 18px;
}

.footer-distributed form input:focus {
  outline: none;
}

/* Changing the placeholder color */

.footer-distributed form input::-webkit-input-placeholder {
  color: #999;
}

.footer-distributed form input::-moz-placeholder {
  opacity: 1;
  color: #999;
}

.footer-distributed form input:-ms-input-placeholder {
  color: #999;
}

/* The magnify glass icon */

.footer-distributed form i {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 16px;
  right: 18px;
  color: #999;
  font-size: 18px;
  margin-top: 6px;
}

.footer-distributed .footer-company-name span {
  color: #ffffff;
  cursor: pointer;
}

.footer-distributed .footer-company-name a:hover {
  color: #9dc15b;
}
.footer-links-soi {
  margin: 0px;
  padding: 0px;
}
.footer-links-soi li {
  display: inline-block;
  margin-right: 5px;
}
.footer-links-soi li a {
  background: #ffffff;
  color: #333333;
  width: 32px;
  height: 32px;
  display: block;
  font-size: 22px;
  text-align: center;
  line-height: 32px;
}
.footer-links-soi li a:hover {
  background: linear-gradient(0deg, #538234 30%, #82b260 67%);
}
.footer-links-soi li a:hover svg {
  color: #ffffff;
  -webkit-transform: rotateY(360deg);
  transform: rotateY(360deg);
}

.footer-links-soi li a i {
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

@media (max-width: 767px) {
  .copyrights {
    padding: 20px 0px;
    text-align: center;
  }
  .footer .widget {
    margin-bottom: 30px;
  }
  .footer-distributed .footer-right {
    float: none;
  }
  .footer-left {
    float: none !important;
  }
}
@media only screen and (min-width: 280px) and (max-width: 599px) {
  .copyrights {
    padding: 20px 0px;
    text-align: center;
  }
  .footer-distributed .footer-right {
    float: none;
  }
  .footer .widget {
    margin-bottom: 30px;
  }
  .footer-left {
    float: none !important;
  }
}

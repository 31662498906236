.all-title-box-aboutus {
  background: url(/src/img/desenfoqueaboutus.jpg) no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  min-height: 300px;
  margin-top: initial;
}
.shadow-img {
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.33);
}
